export function useFormTracking() {
  const { analyticsTrack } = useAnalytics();

  function trackForm(contactForm, contactButtons, formUrl, locale) {
    if (contactForm) {
      let contactFormSession = sessionStorage.getItem('contact_started');

      if (!contactFormSession) {
        contactButtons.forEach((el) => {
          el.addEventListener('click', () => {
            let nodeList = [];
            const inputs = document.querySelectorAll(
              '#contact-us-modal input:not([type="hidden"])'
            );
            const selects = document.querySelectorAll(
              '#contact-us-modal select'
            );
            nodeList = [...inputs, ...selects];
            // if contact form hasn't started track click
            nodeList.forEach((element) => {
              element.addEventListener('click', () => {
                analyticsTrack('Form Started', {
                  form_type: 'contact us',
                  form_url: formUrl,
                  locale_site: locale,
                });
                sessionStorage.setItem('contact_started', true);
                contactFormSession = sessionStorage.getItem('contact_started');
              });
            });
          });
        });
      }
    }
  }

  return {
    trackForm,
  };
}

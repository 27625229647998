<template>
  <div>
    <ModularHeader
      :hide-banner="true"
      :data="page.fields?.navigation ?? {}"
      :is-transparent="page.fields.transparentNav || false"
    />
    <div class="page--index">
      <ModularRow
        v-for="(content, index) in page.fields.rows"
        :key="index"
        :row="content"
      />
    </div>
    <FootersTheFooter
      :data="page.fields.navigation ? page.fields.navigation : null"
    />
    <BannersAnnouncementBannerFooter />
  </div>
</template>

<script setup>
  import {
    generateAvailableLocales,
    generateHreflangLinks,
    generateHead,
  } from '~/utils/meta-generators';

  defineProps({
    id: {
      type: String,
      required: false,
      default: null,
    },
  });

  const { locale, baseUrl } = useI18n();
  const { fullPath } = useRoute();
  const { $contentfulClient, $getContentfulPage } = useNuxtApp();
  const { optimizelyContentfulExp, isExpRunning } = useOptimizely();
  const { trackForm } = useFormTracking();
  const { trackExperiment } = useAnalytics();
  const { ctflProdSpaceId } = useRuntimeConfig().public;
  const expVariation = useState('value');
  const isExperimentRunning = useState('value');
  const widgetStore = useDevModeWidgetStore();

  const contentfulEnv = `https://app.contentful.com/spaces/${ctflProdSpaceId}/entries/2Rxv7E4uIGrPHa3UMdEerV`;
  widgetStore.saveEntryURL(contentfulEnv, fullPath);

  const options = {
    'sys.id': '2Rxv7E4uIGrPHa3UMdEerV',
    content_type: 'modularPage',
  };

  const { data, error } = await useAsyncData('fetch-index', async () => {
    const [entry, allLocales] = await Promise.all([
      $getContentfulPage({ options }),
      $contentfulClient.getEntries({
        include: 1,
        'sys.id': '2Rxv7E4uIGrPHa3UMdEerV',
        locale: '*',
      }),
    ]);

    return { entry, allLocales };
  });

  const page = data.value.entry;

  if (error.value) {
    throw createError({ statusCode: 404 });
  }

  const meta = generateHead(
    page.fields.metaSection.fields.metaTitle,
    page.fields.metaSection.fields.metaDescription,
    page.fields.metaSection.fields.indexing,
    page.fields.metaSection.fields.metaImage &&
      page.fields.metaSection.fields.metaImage.fields.image.fields.file
      ? `https:${page.fields.metaSection.fields.metaImage.fields.image.fields.file.url}`
      : '',
    generateHreflangLinks(
      generateAvailableLocales(
        data.value.allLocales.items[0].fields.availableInLocale
      ),
      locale.value,
      baseUrl.value,
      fullPath,
      true
    )
  );

  useHead(meta);

  if (page.fields.experimentRows) {
    if (process.server) {
      optimizelyContentfulExp(page.fields.experimentRows.fields).then(
        (token) => {
          if (token.ctflKey !== undefined) {
            expVariation.value = token.variation;
            const row = page.fields.experimentRows.fields.variations.find(
              (obj) => {
                return obj.sys.id === token.ctflKey;
              }
            );
            page.fields.rows = row.fields.rows;
          }
        }
      );

      isExperimentRunning.value = isExpRunning(
        page.fields.experimentRows.fields
      );
    }
  }

  onMounted(() => {
    if (
      isExperimentRunning.value &&
      page.fields.experimentRows?.fields?.experimentKey
    ) {
      trackExperiment({
        path: fullPath,
        expRows: page.fields.experimentRows,
        expKey: page.fields.experimentRows.fields.experimentKey,
        variation: expVariation.value,
      });
      trackForm(
        document.querySelector('#contact-us-modal'),
        document.querySelectorAll('a[data-id*="contact-us-modal"]'),
        fullPath,
        locale.value
      );
    }
  });
</script>

<style lang="scss" scoped>
  .page--index {
    :deep(.hero--tall) {
      font-size: 30px;
      line-height: 40px;
      a {
        line-height: 19px;
      }
      @screen sm {
        h1 {
          font-size: 58px;
          white-space: normal;
        }
      }

      h5 {
        letter-spacing: 2px;
      }
    }
  }

  :deep(.swiper-slide) {
    h3 {
      font-size: 20px;
      line-height: 30px;
      min-height: 135px;
      padding: 0 15px;

      @media (min-width: theme('screens.md')) {
        min-height: 150px;
      }

      @media (min-width: theme('screens.lg')) {
        min-height: 135px;
      }
    }
  }
</style>
